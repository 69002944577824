import React from 'react';
import { Marker, useMap, Tooltip } from 'react-leaflet';
import L from 'leaflet';
import moment from 'moment';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
});

function LocationMarker(props) {
    const map = useMap()
    var idLocale = require('moment/locale/id');
    moment.locale('id', idLocale);
    if(props.positions != null){
        if(props.positions.length > 0){
            map.flyTo([props.positions[0].lokasi.latitude, props.positions[0].lokasi.longitude], 18)
        }
    }

    return (  
        <>
            {
                props.positions.map((point, index) => (
                    <Marker key={index} position={[point.lokasi.latitude, point.lokasi.longitude]}>
                        <Tooltip direction="top">
                            <i className="fa fa-calendar"></i>&nbsp;
                        {moment(point.waktu.toDate()).format('dddd, DD MMMM YYYY H:mm:ss')}
                        </Tooltip>
                    </Marker>

                ))
            }
        </>
    )
}

export default LocationMarker;