import React, { Component } from "react";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { alert_error, alert_success } from "../../../../services/Alert/AlertSweet";
import { StateToProps } from "../../../../services/redux_/StateToProps";
import _Api from "../../../../services/_Api";
import { ButtonAttachFile, ButtonUplod, Prog_Progresbar } from "../../../../services/_Button";
import uploadFilesService from "../../../../services/_Upload/upload-files.service";
// import uploadFilesService from "../../../../services/_Upload/upload-files.service";
// import uploadFilesService from "../services/upload-files.service";

class UploadFiles extends Component {
    constructor(props) {
        super(props);
        this.selectFiles = this.selectFiles.bind(this);
        this.upload = this.upload.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.fileUpload = React.createRef();
        this.showFileUpload = this.showFileUpload.bind(this);

        this.state = {
            selectedFiles: undefined,
            progressInfos: [],
            message: [],
            status: 0,
            msg: "",
            loading: false,
            // id_kantah : props.
            fileInfos: [],
            listFile: {
                namaFile: ""
            }
        };
    }

    // saveNamaFile(fn) {
    //     const id_kantah = this.props.userLogin.id_kantah
    //     _Api.post("/kantah/wms/upload/",{ file : fn }).then(res=>{
    //         console.log(res)
    //     });

    // }


    upload(idx, file) {
        let _progressInfos = [...this.state.progressInfos];
        var fileName = file.name.replace(/\.[^/.]+$/, "");

        uploadFilesService.upload(fileName, file, (event) => {
            _progressInfos[idx].percentage = Math.round((100 * event.loaded) / event.total);
            this.setState({
                _progressInfos,
                status: 0
            });
        })
            .then((res) => {
                console.log(' statuss   ', this.state.status)
                this.setState({ loading: false, msg: "success" })
                let msg_success = "Success : Berhasil upload file : " + file.name;
                this.setState((prev) => {
                    let nextMessage = [...prev.message, msg_success];
                    return {
                        message: nextMessage,
                        status: 1
                    };
                });

            })
            .catch(() => {
                if (this.state.status == 0) {
                    _progressInfos[idx].percentage = 0;
                    this.setState((prev) => {
                        let nextMessage = [...prev.message, "Error : Gagal upload file : " + file.name];
                        // console.log("cath : ", nextMessage)

                        return {
                            progressInfos: _progressInfos,
                            message: nextMessage
                        };
                    });
                    this.setState({ loading: false, msg: "error" })

                }
            });
    }

    uploadFiles() {
        this.setState({ loading: true })
        const selectedFiles = this.state.selectedFiles;

        let _progressInfos = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }

        this.setState(
            {
                progressInfos: _progressInfos,
                message: [],
                status: 0
            },
            () => {
                for (let i = 0; i < selectedFiles.length; i++) {
                    this.upload(i, selectedFiles[i]);
                }
            }
        );
    }

    showFileUpload() {
        this.fileUpload.current.click();
    }

    selectFiles(event) {


        const selectedFiles = event.target.files;

        let _progressInfos = [];

        for (let i = 0; i < selectedFiles.length; i++) {
            _progressInfos.push({ percentage: 0, fileName: selectedFiles[i].name });
        }


        this.setState({
            progressInfos: _progressInfos,
            selectedFiles: event.target.files,
        });



    }



    render() {
        const { selectedFiles, progressInfos, message, fileInfos } = this.state;

        return (
            <div>
                {progressInfos &&
                    progressInfos.map((progressInfo, index) => (
                        <div className="mb-2" key={index}>
                            <span>{progressInfo.fileName}</span>
                            <div className="progress">
                                <Prog_Progresbar percentage={progressInfo.percentage}>
                                    {progressInfo.percentage}%
                                </Prog_Progresbar>
                            </div>
                        </div>
                    ))}

                <div className="row my-3">
                    <div className="col-7">
                        <label className="btn btn-default p-0">
                            <input
                                type="file"
                                accept=".zip"
                                id="my_file"
                                multiple
                                onChange={this.selectFiles}
                                style={{ display: "none" }}
                                ref={this.fileUpload}
                            />
                        </label>
                    </div>
                    <div className="col-12">
                        {this.state.msg === "success" && <p className="alert alert-success"> Berhasil uploa file !</p>}
                        {this.state.msg === "error" && <p className="alert alert-danger"> Gagal uploa file !</p>}

                    </div>
                    <div className="col-5 flex">

                        {this.state.loading === true ?
                            <ButtonUplod disabled title="Choose File" /> :
                            <ButtonAttachFile onClick={this.showFileUpload} title="Choose File" />
                        }
                                &nbsp;
                          {this.state.loading === true ?
                            <ButtonUplod disabled title="Upload" /> :
                            <ButtonUplod onClick={this.uploadFiles} title="Upload" />
                        }

                    </div>
                </div>

                {/* {message.length > 0 && (
                    <div className="alert alert-secondary" role="alert">
                        <ul>
                            {message.map((item, i) => {
                                return <li key={i}>{item}</li>;
                            })}
                        </ul>
                    </div>
                )} */}
            </div>
        );
    }
}



export default injectIntl(connect(
    StateToProps,
)(UploadFiles));

