import ActionType from "./ActionType";

export const DispatchToProps = dispatch => {
    return {

        TambahData: () => dispatch({ type: ActionType.TAMBAH_DATA }),
        setJenis: (pJenis) => dispatch({ 
            type: ActionType.SET_JENIS,
            pJenis : pJenis  
        }),
        setKantahAktif: (pKantah) => dispatch({
            type: ActionType.DATA_KANTAH,
            kantahBaru: JSON.stringify(pKantah)
        }),
        setUserLogin: (pUser) => dispatch({
            type: ActionType.USER_LOGIN,
            user: pUser
        })
    }
};