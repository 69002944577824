import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfigProd = {
  apiKey: "AIzaSyDFAq5NEW9MlIp92nrDFgFnyeHkdvIguxM",
  authDomain: "smartgis-1541557963702.firebaseapp.com",
  databaseURL: "https://smartgis-1541557963702.firebaseio.com",
  projectId: "smartgis-1541557963702",
  storageBucket: "smartgis-1541557963702.appspot.com",
  messagingSenderId: "252189693480",
  appId: "1:252189693480:web:c3eace4977f5916fff871d",
  measurementId: "G-T457YE5JDW",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfigProd);

export const auth = firebase.auth();
export const db = firebase.firestore();
export const config = firebaseConfigProd;
export const provider = new firebase.auth.GoogleAuthProvider();

export default firebase;
