import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FirestoreCollection, FirestoreProvider } from "@react-firebase/firestore";
import { MapContainer, TileLayer, Popup, Polygon, Marker, Polyline, LayersControl, Tooltip, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'
import { useHistory, useParams } from 'react-router-dom';
import L from 'leaflet';
import firebase from 'firebase/app'
import { Spinner, ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import { config } from '../../../service/firebase';
import _Api from '../../../../services/_Api';
import { useSubheader } from '../../../../_metronic/layout';
import { Autocomplete } from '@material-ui/lab';
import { Button, Checkbox, FormControlLabel, LinearProgress, TextField } from '@material-ui/core';
import { Btn_Delete, ButtonDownload, ButtonFullScreen, ButtonFullScreenExit, ButtonUplod } from '../../../../services/_Button';
import { useSelector } from 'react-redux';
import moment from 'moment';
import useWindowDimensions from '../../../../services/Windows/getWindowDimensions';
import { Check } from '@material-ui/icons';
// import useWindowDimensions from '../../../../services/Windows/getWindowDimensions';
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'


const iconPU = L.icon({
  iconUrl: require('../../../../_metronic/_assets/img/IconSurveyor.svg'),
  iconSize: [70, 60],
  iconAnchor: [25, 35],
  popupAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null
});




export const LokasiKantah = () => {
  const [zoom, setZoom] = useState(11)
  const suhbeader = useSubheader();
  suhbeader.setTitle("Peta Area");

  const [listPU, setlistPU] = useState([]);
  const [showlabel, setshowlabel] = useState(false);
  const [loading, setloading] = useState(false);
  const [tinggi, settinggi] = useState(sessionStorage.getItem("height"));
  const [loading1, setloading1] = useState(false);
  const [emailpu, setemailpu] = useState(null);
  const [namaPu, setNamaPu] = useState(null);
  const [center, setCenter] = useState([-8.590998, 116.093376]);
  const [map, setMap] = useState(null)
  const { wHeight, wWidth } = useWindowDimensions();

  const { user } = useSelector(state => state.auth);

  const histori = useHistory();
  var idLocale = require('moment/locale/id');
  moment.locale('id', idLocale);

  const cekLokasi = (email, nama) => {
    return (
      <FirestoreCollection key={1} path={"/smartgis/" + email + "/location"}>
        {d => {
          try {
            if (d.value) {
              let lok = d.value[0].lokasi;
              let lastonline = d.value[0].created_at;
              let pos = [lok.latitude, lok.longitude];
              map.flyTo(pos, zoom)
              return d.isLoading ? setloading(true) :
                <div>
                  {setloading(false)}
                  <Marker position={pos} icon={iconPU}>
                    {
                      showlabel ?
                        <Tooltip permanent >{nama + ' | Online Terakhir : ' + moment(lastonline.toDate()).format('dddd, DD MMMM YYYY H:mm')}</Tooltip> :
                        <Tooltip >{nama + ' | Online Terakhir : ' + moment(lastonline.toDate()).format('dddd, DD MMMM YYYY H:mm')}</Tooltip>

                    }
                  </Marker>
                </div>
            }

          } catch (error) { }
        }}
      </FirestoreCollection>

    )
  }

  const cekPosisi = (email) => {
    setloading1(true)
  }
  const changeLabel = () => {
    setshowlabel(!showlabel)
    loadData()
  }


  // const onClick = () => {
  //   cekLokasi(emailpu)
  //   console.log(emailpu)
  // }


  const mappPetugasPu = listPU.map((item, index) => (
    <div key={index}>
      {cekLokasi(item.email, item.nama)}
    </div>
  ))

  const gotoFullscreen = () => {
    histori.push("lokasikantahh/full")
  }
  const loadData = () => {
    setlistPU([])
    setloading(true)
    _Api.get(`kantah/${user.kantah.kode}/pu`).then(res => {
      setlistPU(res.data)
      setloading(false)
    })
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 122) {
      gotoFullscreen()
    }
  }


  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      <div className="row" style={{ overflow: "auto" }} onKeyDown={handleKeyDown}>
        <div className="col-md-12">
          <div className="card card-custom gutter-b example example-compact">
            {/* <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">LOKASI Surveyor</h3>
              </div>

            </div> */}
            <div className="card-body">
              <div className="flex">

                <Autocomplete
                  fullWidth
                  label="Surveyor"
                  onChange={(e, nw) => {
                    if (nw) {
                      setemailpu(nw.email)
                      setNamaPu(nw.nama)
                      cekLokasi(nw.email, nw.nama)
                    } else setloading1(false)
                  }}
                  options={listPU}
                  getOptionSelected={(option, value) => option.id === value.id}
                  getOptionLabel={(option) => option.nama}
                  renderInput={(params) =>
                    <TextField {...params}
                      className="form-control"
                      label="Surveyor"
                      // style={{ marginLeft: "0px", width: "200px" }}
                      fullWidth
                      margin="normal"
                      variant="filled" />}
                />

                <ButtonDownload onClick={() => cekPosisi()} style={{ marginTop: "15px" }} title="Telusuri" />
                <ButtonFullScreen onClick={gotoFullscreen} style={{ marginTop: "15px" }} title="Full" />
                <ButtonFullScreenExit onClick={() => histori.push("/LokasiKantah")} style={{ marginTop: "15px" }} title="Exit" />

                <FormControlLabel style={{ marginTop: "15px" }}
                  control={<Checkbox checked={showlabel} onChange={changeLabel} name="gilad" />}
                  label="Show Label"
                />

              </div>
              <br />
              {loading && <LinearProgress color="secondary" />}
              <MapContainer style={{ width: '100%', height: 1008, zIndex: 3 }}
                whenCreated={setMap}

                center={center} zoom={18}  scrollWheelZoom={true}>
                <LayersControl position="topright">
                  <LayersControl.BaseLayer checked name="Roadmap">
                    <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'roadmap'} />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Satellite">
                    <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'satellite'} />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Terrain">
                    <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'terrain'} />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="Hybrid">
                    <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'hybrid'} />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                  </LayersControl.BaseLayer>
                  <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                    <TileLayer
                      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                      url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                    />
                  </LayersControl.BaseLayer>
                </LayersControl>

                <FirestoreProvider {...config} firebase={firebase}>
                  {loading1 ? cekLokasi(emailpu, namaPu) : mappPetugasPu}

                  {/* <Marker position={[]}>
                    <Tooltip>{JSON.stringify(location)}</Tooltip>
                  </Marker> */}

                </FirestoreProvider>

              </MapContainer>
            </div>
          </div>
        </div>
      </div>
    </>

  );
};
