import React, { useState } from 'react';
import { useFirestore, useFirestoreCollectionData } from 'reactfire'
import { Spinner } from 'react-bootstrap';
import SubPoint from "./SubPoint"

const TotalPoint = React.memo((props) => {
    const { email, from, to } = props;
    const [total, setTotal] = useState(0);
    const point = useFirestore()
        .collection("/smartgis/" + email + "/workspaces");

    // subscribe to a document for realtime updates. just one line!
    const { status, data } = useFirestoreCollectionData(point);
   
    var jumlah = 0;
    if (data != null) {
        jumlah = data.length;

    } else {
        jumlah = 0;
    }

    // console.log(jumlah)
    // return null;

    if (status == 'loading') {
        return <Spinner animation="grow" />
    }
    return(
        jumlah == 0 ? '0' :
                <SubPoint email={email} data={data}  from={from} to={to} />
         
    )

})

export default TotalPoint;