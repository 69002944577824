import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, ButtonGroup, Grid, IconButton, LinearProgress, TableFooter, TablePagination, TextField } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import _Api from '../../../services/_Api';
import { useHistory } from 'react-router-dom';
import SVG from "react-inlinesvg";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import ButtonDelete from '../../../services/_Button/Button-delete';
import ButtonUpdate from '../../../services/_Button/Button-update';
import ButtonNew from '../../../services/_Button/Button-New';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { StateToProps } from '../../../services/redux_/StateToProps';
import { DispatchToProps } from '../../../services/redux_/DispatchToProps';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Dialog from '@material-ui/core/Dialog';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import ButtonSave from '../../../services/_Button/Button-Save';
import {Button as But, Form} from 'react-bootstrap'
import moment from 'moment';



const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}));

function ManajementUser(props) {
  const classes = useStyles();
  const [rows, setrows] = useState([])
  const histori = useHistory()
  const [loading, setloading] = useState(true)
  const [updateOpen, setupdateOpen] = useState(false)
  const [datauser, setDatauser] = useState()
  const [updatedatauser, setupdatedatauser] = useState()
  // PAGINATE TABLE ===
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };


  const handleChange = name => event => {
    setupdatedatauser({
        ...updatedatauser,
        "expire_at": null,
        // "password": null,
        [name]: event.target.value
    });
  };



  // END PAGINATE ===


  const loadData = () => {
    _Api.get("/list").then(res => {
      setrows(res.data.results)
      setloading(false)
    })
  }

  const doDelete = (id) => {
    let succ = 1;
    _Api.delete("/users/" + id).catch(err => succ = 0)

    if (succ = 1) {
      loadData()
    } else {
      alert('gagal ..!')
    }

  }
  const deleteUser = (id) => {
    Swal.fire({
      title: 'Hapus data ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        doDelete(id)

      }
    })

  }



   const  createOrUpdate = async id => {
      setupdateOpen(true)
      await _Api.get(id + "/get").then(res => {
        setDatauser(res.data)
        setupdatedatauser(
          {
            "username":res.data.username,
            "nama": res.data.name,
            "email": res.data.email,
            "password": null,
            "role" : "kantah",
            "kantah": res.data.kantah ? res.data.kantah.kode:"" 
          }
        )
      })


     
  }

  const handleSave = () => {
    console.log(datauser)
    setloading(true)
    _Api.put(datauser.id+"/update", updatedatauser).then(res => {
        setupdateOpen(false)
        loadData()
    })
  }


  const cariData = (e) => {
    _Api.get("/users?username=" + e.target.value).then(res => {
      setrows(res.data)
    })
  }

  useEffect(() => {
    loadData()
  }, [])

  const loginAs = (user_id)=>{
    _Api.get(`login-as/${user_id}`).then(res => {
      // console.log(res)
      if (res.data) {
        var obj =
        {
          'authToken': '"' + res.data.access_token + '"',
          'user': JSON.stringify(res.data),
          '_persist': '{"version":-1,"rehydrated":true}',
        };
        // console.log(res.data[0])
        localStorage.setItem('persist:auth', JSON.stringify(obj));
        localStorage.setItem('Authorization', res.data.access_token);
        console.log(props)
        window.location.href= "/dashboard"
      }
     
    })
  }

 

  const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);

  const renderData = (rowsPerPage > 0
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows).map((row, index) => (
      <StyledTableRow key={index}>
        <StyledTableCell>{index + 1}  </StyledTableCell>
        <StyledTableCell>{row.username}</StyledTableCell>
        <StyledTableCell>{row.name}</StyledTableCell>
        <StyledTableCell>{row.email}</StyledTableCell>
        <StyledTableCell>{row.role}</StyledTableCell>
        <StyledTableCell>  {row.expire_at && moment(row.expire_at).format('DD-MM-YYYY')}</StyledTableCell>
        <StyledTableCell>
          <ButtonDelete onClick={() => deleteUser(row.id)} />
          <ButtonUpdate onClick={() => createOrUpdate(row.id)} />
          <But variant="outline-dark" title="Login As" onClick={() => loginAs(row.id)}><i className="fa fa-key"></i></But>

        </StyledTableCell>
       
      </StyledTableRow>
    ))
  return (
    <Paper className={classes.root}>

      <Dialog
        open={updateOpen}
        // onEntering={loadData}
        // style={{width:"400px"}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        {/* <DialogTitle></DialogTitle> */}
        <DialogContent>
          <h3 style={{ textAlign: "center" }}> UPDATE USER </h3>
          <DialogContentText>
            <ValidatorForm onSubmit={handleSave}>
              <TextValidator
                label="Nama Lengkap"
                value={updatedatauser && updatedatauser.nama}
                onChange={handleChange('nama')}
                margin="normal"
                variant="filled"
                validators={['required']}
                errorMessages={['Silahkan dilengkapi ']}
              />

              <TextValidator
                label="Password"
                // value={datauser.username}
                onChange={handleChange('password')}
                margin="normal"
                variant="filled"
                type ="password"
              />

              <TextField
                id="date"
                label="Expire"
                type="date"
                margin="normal"
                value={updatedatauser && updatedatauser.expire_at}
                onChange={handleChange('expire_at')}
                variant="filled"
                className={classes.textField}
                InputLabelProps={{
                    shrink: true,
                }}
            />

            </ValidatorForm>
            <Button
              onClick={() => setupdateOpen(false)} color="primary" variant="contained">
              Tutup
            </Button> &nbsp;
            <ButtonSave onClick={handleSave} title="Simpan" />
          </DialogContentText>
        </DialogContent>
      </Dialog>


      <Grid style={{ display: "flex" }}>
        <Grid item xs={9}>

          <ButtonNew onClick={() => createOrUpdate(0)} style={{ margin: "25px 0px 0 10px" }} />

        </Grid>
        <Grid item xs={3}>
          <TextField
            onChange={cariData}
            label="Username"
            margin="normal"
          />
        </Grid>
      </Grid>
      {loading && <LinearProgress color="secondary" />}

      <Table className={classes.table}>
        <TableHead>
          <TableRow hover>

            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell >Username</StyledTableCell>
            <StyledTableCell >Nama</StyledTableCell>
            <StyledTableCell >Email</StyledTableCell>
            <StyledTableCell >Role</StyledTableCell>
            <StyledTableCell width="200" >Expire At</StyledTableCell>
            <StyledTableCell width="300" >Action</StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {renderData}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={3}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
            />

          </TableRow>
        </TableFooter>


      </Table>
    </Paper>
  );
}


export default injectIntl(connect(
  StateToProps,
  DispatchToProps
)(ManajementUser));

