import React from 'react';
import { useFirestore, useFirestoreDocData, useFirestoreCollectionData } from 'reactfire'
import {Spinner} from 'react-bootstrap';

const TotalLine = React.memo((props) => {
    const { email, from, to } = props;
    const burritoRef = useFirestore()
        .collection("/smartgis/" + email+"/polyline_areas")
        .where('created_at','>=',from)
        .where('created_at','<=',to);

    // subscribe to a document for realtime updates. just one line!
    const { status, data } = useFirestoreCollectionData(burritoRef);
    if(status == 'loading'){
        return <Spinner animation="grow" />
    }
    
    return <>{data?.length ?? 0}</>;

})

export default TotalLine;
