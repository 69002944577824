import React, {useEffect, useMemo, useState} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../../_core/MetronicLayout";
import { AsideMenuListKantah } from "./AsideMenuList-kantah";
import { injectIntl } from "react-intl";
import { connect, useSelector } from "react-redux";
import { StateToProps } from "../../../../../services/redux_/StateToProps";
import { DispatchToProps } from "../../../../../services/redux_/DispatchToProps";
import _Api from "../../../../../services/_Api";

function AsideMenu(props) {
  const {user} = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true)
    };
  }, [uiService]);
  

 
  

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        { user.role == "admin" ? <AsideMenuList layoutProps={layoutProps} /> : <AsideMenuListKantah layoutProps={layoutProps} /> }
        
      </div>
      {/* end::Menu Container */}
    </>
  );
}



export default injectIntl(connect(
  StateToProps,
  DispatchToProps
)(AsideMenu))
