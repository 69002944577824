import ActionType from "./ActionType";
import GlobalState from "./GlobalState";


export const reducer = (state = GlobalState, action) => {
  switch (action.type) {
    case ActionType.TAMBAH_DATA:
      return {
        ...state,
        totalKantah: state.totalKantah + 1
      }
      break;


      case ActionType.DATA_KANTAH:
        return {
          ...state,
          kantahAktif: action.kantahBaru
        }
        break;

      case ActionType.SET_JENIS:
        return {
          ...state,
          jenis: action.pJenis
        }
        break;

      case ActionType.USER_LOGIN:
        return {
          ...state,
          userLogin: action.user
        }
        break;



      
    default:
      return state;
  }
}

 