/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import { Button, Spinner, Form } from "react-bootstrap";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { useFirestore, useFirestoreDocData } from 'reactfire'
import _Api from "../../services/_Api"
import { connect, useSelector } from 'react-redux';
import { StateToProps } from "../../services/redux_/StateToProps";
import { injectIntl } from "react-intl";
import { FirebaseAppProvider } from 'reactfire'
import { config } from '../service/firebase';
import TotalPolygon from './dashboard/TotalPolygon'
import TotalPoint from './dashboard/TotalPoint'
import TotalLine from './dashboard/TotalLine'

function DashboarKantah() {
    const { user } = useSelector(state => state.auth);
    const [petugas, setPetugas] = useState([]);
    const [loading, setLoading] = useState(false);
    const [tanggal, setTanggal] = useState(null);
    const [start, setStart] = useState(null);
    const [startstring, setStartstring] = useState(null);
    const [to, setTo] = useState(null);

    useEffect(() => {
        let start = new Date();
        let to = new Date();
        var end = to.getDate() - 7;
        to.setDate(end);
        start.setHours(23);
        start.setMinutes(59);
        start.setSeconds(59);
        to.setHours(0);
        to.setMinutes(0);
        to.setSeconds(0);
        let startstring = start.getFullYear()+"-"+(start.getMonth()+1)+"-"+start.getDate();
        setStart(to);
        setStartstring(startstring);
        setTo(start);
        loadPetugas();
        // console.log("from : "+to)
        // console.log("to : "+start)
    }, [])

    const loadPetugas = () => {
        setLoading(true);
        _Api.get(`kantah/${user.kantah.kode}/pu`)
            .then(res => {
                const persons = res.data;
                setPetugas(persons)
                setLoading(false);
            })
            .catch(error => {
                console.log(error)
                setLoading(false);
            })
    }

    const changeTanggal = name => event => {
        if(name == 'start'){
            let start = new Date(event.target.value);
            start.setHours(0);
            start.setMinutes(0);
            start.setSeconds(0);
            setStart(start);
        }else{
            let end = new Date(event.target.value);;
            end.setHours(23);
            end.setMinutes(59);
            end.setSeconds(59);
            setTo(end);
        }
    }
    return (
        <>
            <FirebaseAppProvider firebaseConfig={config}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className={`card card-custom card-stretch gutter-b`}>
                            {/* Head */}
                            <div className="card-header border-0 pt-5">
                                <h3 className="card-title align-items-start flex-column">
                                    <span className="card-label font-weight-bolder text-dark">
                                        Hasil Pengukuran
                                </span>
                                    <span className="text-muted mt-3 font-weight-bold font-size-sm">
                                        
                                </span>
                                </h3>
                                <div className="card-toolbar">
                                    <Form inline>
                                        <Form.Label className="my-1 mr-6" htmlFor="inlineFormCustomSelectPref">From :</Form.Label>
                                        <Form.Control
                                            onChange={changeTanggal('start')}
                                            type="date"
                                            defaultValue={startstring}
                                        >
                                        </Form.Control>&nbsp;
                                        <Form.Label className="my-1 mr-6" htmlFor="inlineFormCustomSelectPref">To :</Form.Label>
                                        <Form.Control
                                            onChange={changeTanggal('to')}
                                            type="date"
                                            defaultValue={to}
                                        >
                                        </Form.Control>
                                    </Form>
                                </div>
                            </div>
                            {/* Body */}
                            <div className="card-body pt-3 pb-0">
                                {loading ?
                                    <>
                                        <Spinner animation="grow" variant="primary" />
                                        <Spinner animation="grow" variant="secondary" />
                                        <Spinner animation="grow" variant="success" />
                                        <Spinner animation="grow" variant="danger" />
                                        <Spinner animation="grow" variant="warning" />
                                        <Spinner animation="grow" variant="info" />
                                        <br />
                                    </> :

                                    <div className="table-responsive">
                                        <table className="table table-borderless table-vertical-center">
                                            <thead>
                                                <tr>
                                                    <th className="p-0" style={{ width: "50px" }} />
                                                    <th className="p-0" style={{ minWidth: "200px" }} />
                                                    <th className="p-0" style={{ minWidth: "100px" }} />
                                                    <th className="p-0" style={{ minWidth: "125px" }} />
                                                    <th className="p-0" style={{ minWidth: "110px" }} />
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {petugas.map((val, index) => (
                                                    <>
                                                        <tr key={index}>
                                                            <td className="pl-0 py-4">
                                                                <div className="symbol symbol-50 symbol-light mr-1">
                                                                    <span className="symbol-label">
                                                                        <SVG
                                                                            src={toAbsoluteUrl("/media/svg/avatars/001-boy.svg")}
                                                                            className="h-50 align-self-center"
                                                                        ></SVG>
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            <td className="pl-0">
                                                                <a
                                                                    href="#"
                                                                    className="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                                                                >
                                                                    {val.nama}
                                                                </a>
                                                                <div>
                                                                    <span className="font-weight-bolder">Email:</span>{" "}
                                                                    <a
                                                                        className="text-muted font-weight-bold text-hover-primary"
                                                                        href="#"
                                                                    >
                                                                        {val.email}
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className="text-right">
                                                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                    <TotalPolygon email={val.email} from={start} to={to} />
                                                                </span>
                                                                <span className="text-muted font-weight-bold"><i className='fa fa-object-group'></i> Polygon</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                    <TotalLine email={val.email} from={start} to={to} />
                                                                </span>
                                                                <span className="text-muted font-weight-bold"><i className='fa fa-road'></i> Polyline</span>
                                                            </td>
                                                            <td className="text-right">
                                                                <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                                                    <TotalPoint email={val.email} from={start} to={to}/>
                                                                </span>
                                                                <span className="text-muted font-weight-bold"><i className='fa fa-map-pin'></i> Point</span>
                                                            </td>
                                                        </tr>
                                                    </>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </FirebaseAppProvider>
        </>
    );
}

export default injectIntl(connect(StateToProps, null)(DashboarKantah));
