import React, { useState, useCallback } from 'react';
import { db } from '../../service/firebase'

const SubPoint = React.memo((props) => {
    const { email, data, from, to } = props;
    const [total, setTotal] = useState(0)
 
    var jumlah = 0;
    const ganti = useCallback((nilai) => {
        setTotal(nilai)
        // jumlah += nilai
    }, [])

    const cek = async (val) => {

        const put = await db.collection("/smartgis/" + email + "/workspace_points/" + val.NO_ID_FIELD + "/data")
            .where('created_at', '>=', from)
            .where('created_at', '<=', to)
            .get().then(function (doc) {

                jumlah = jumlah + (doc.docs.length);
                return jumlah
            })

        return put;
    }
    // callback()
    const lagi = async () => {
        const has = await mantul();
        ganti(has)
       
    }
    const mantul = async () => {
        let sem;
        await data.map(val => {
            const b = cek(val)
            sem = b;
        })
        return sem
    }
    if (data != null) {
        lagi()
    }

    return total
})

export default SubPoint;