import Axios from 'axios'
export const baseURL = process.env.REACT_APP_BASE_URL

const Api = () => {
    const defaultOptions = {
      baseURL:baseURL,
      headers: {
        'Content-Type': 'application/json',
        // 'X-AUTH-TOKEN' :sessionStorage.getItem('tokenauthC'),
        // 'X-CSRF-Token' : sessionStorage.getItem('tokencrsf')
      },
    };
  
    let instance = Axios.create(defaultOptions)  
    instance.interceptors.request.use(function (config) {
      let auth = localStorage.getItem('Authorization');
      const token =  auth ? auth : 'unAuthorization';
      // const token = 'Authorization';
      config.headers.Authorization =  token ? `Bearer ${token}` : '';
      return config;
    });
  
    return instance;
  };
  export default Api();
