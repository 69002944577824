import React, { useState } from "react";
import _Api from "../../../services/_Api";
import { ButtonUplod } from "../../../services/_Button";
import Swal from "sweetalert2";

let auth = JSON.parse(localStorage.getItem("persist:auth"));
let currentUser = JSON.parse(auth?.user);

export const UploadShp = () => {
  const [progressInfos, setProgressInfos] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const upload = () => {
    setLoading(true);
    Swal.fire({
      title: "Uploading...",
      text: "Silahkan tunggu sampai proses upload selesai.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    let formData = new FormData();
    formData.append("file", selectedFiles[0]);
    formData.append("kantah_id", currentUser.kantah.kode);
    _Api
      .post("/pu/shp/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        Swal.close();
        Swal.fire({
          title: "Berhasil!",
          text: "File SHP berhasil diupload.",
          icon: "success",
        });
        setLoading(false);
      })
      .catch((error) => {
        Swal.close();
        Swal.fire({
          title: "Gagal!",
          text: "File SHP gagal diupload.",
          icon: "error",
        });
        setLoading(false);
      });
  };

  const selectFiles = (event) => {
    const selected = event.target.files;
    setSelectedFiles(selected);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="card card-custom gutter-b example example-compact">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label">Upload SHP</h3>
            </div>
          </div>
          <div className="card-body">
            {/* {progressInfos &&
              progressInfos.map((progressInfo, index) => (
                <div className="mb-2" key={index}>
                  <span>{progressInfo.fileName}</span>
                  <div className="progress">
                    <Prog_Progresbar percentage={progressInfo.percentage}>
                      {progressInfo.percentage}%
                    </Prog_Progresbar>
                  </div>
                </div>
              ))} */}
            <div className="form-group mb-4">
              <label className="form-label required">File SHP</label>
              <input
                className="form-control"
                type="file"
                accept=".zip"
                id="my_file"
                multiple
                onChange={selectFiles}
                // ref={this.fileUpload}
              />
            </div>
            <ButtonUplod onClick={() => upload()} title="Upload" />
          </div>
        </div>
      </div>
    </div>
  );
};
