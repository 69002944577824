import Swal from 'sweetalert2'

export const alert_success = (text) => {
    Swal.fire({
        title: "Sukses .!", 
        text : text?text :"",
        icon: "success"
    })
}

export const alert_error = (text) => {
    Swal.fire({
        title: "Error .!", 
        text : text?text :"",
        icon: "error"
    })
}