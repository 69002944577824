/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useMemo} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {connect, useSelector} from "react-redux";
import objectPath from "object-path";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import {UserProfileDropdown} from "./dropdowns/UserProfileDropdown";
import { injectIntl } from "react-intl";
import { StateToProps } from "../../../../services/redux_/StateToProps";
import { DispatchToProps } from "../../../../services/redux_/DispatchToProps";
import _Api from "../../../../services/_Api";
import moment from "moment";

function QuickUserToggler(props) {
  const {user} = useSelector(state => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);


  return (<>
        {layoutProps.offcanvas && (<OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
        >
          <div className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
                 id="kt_quick_user_toggle">
              <>
              {/* <p className="alert alert-warning" style={{backgroundColor:"#3c4444", borderColor:"#b5b5c3"}}> Expire :  {moment(user.expire_at).format("DD MMMM YYYY") } </p> */}
               { user.expire_at ? <span style={{color:"red", fontWeight:"bold", marginRight:"10px"}}> 
                Masa Berlaku Akses SmartPortal Anda : &nbsp; {moment(user.expire_at).format("DD MMMM YYYY") }</span> :
                <span> .... </span>
                }
                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3" >
                 {user.fullname}
                </span>
                <span className="symbol symbol-35 symbol-light-success">                
                    <span className="symbol-label font-size-h5 font-weight-bold">{user.name[0]}</span>
                </span>
              </>
            </div>
          </div>
        </OverlayTrigger>)}

        {!layoutProps.offcanvas && (<UserProfileDropdown/>)}
      </>
  );
}
  export default connect(
    StateToProps
  )(QuickUserToggler)
