import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { MapContainer, TileLayer, LayersControl, WMSTileLayer, useMap } from 'react-leaflet'
import L from 'leaflet';
import { BoundWMS } from './BoundWMS';
import { Loader } from "@googlemaps/js-api-loader"
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'

export const ModalWms = (props) => {
    const { show, setShow, x1, x2, y1, y2, wms_id } = props;
    // const map = useMap();
    var point1 = L.latLng(x1, y1);
    var point2 = L.latLng(x2, y2);
    var bounds = L.latLngBounds(point1, point2);
    // const [lihat, setLihat] = useState(false)
    // const Coba = () => {
    // var myWMS = L.tileLayer.wms("http://180.178.109.123:8080/geoserver/smartportal/wms", {
    //     layers: "smartportal:portal",
    //     format: 'image/png',
    //     transparent: true,
    //     cql_filter: wms_id,
    //     zIndex: 600,
    //     opacity: 1
    // }).addTo(map)


    //     myWMS.setParams({ CQL_FILTER: "wms_id = 42" });
    //     return null;
    // }
    // const loader = new Loader({
    //     apiKey: "AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg",
    //     version: "weekly",
    //     libraries: ["places"]
    // })

    // loader.load()
    //     .then(() => {
    //         setLihat(true)
    //     }).catch((e) => {
    //         console.log(e)
    //     })

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-xl"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        WMS
          </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ padding: '0px' }}>
                    <MapContainer style={{ width: '100%', height: 500, zIndex: 3 }} center={[-7.7823762951277455, 110.34292157739401]} zoom={14} maxZoom={18} scrollWheelZoom={true}>
                        <LayersControl position="topright">
                        <LayersControl.BaseLayer checked name="Roadmap">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'roadmap'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Satellite">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'satellite'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Terrain">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'terrain'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Hybrid">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'hybrid'} />
                            </LayersControl.BaseLayer>
                        
                        {/* <LayersControl.Overlay checked name="WMS">
                                <WMSTileLayer
                                    attribution='Weather data © 2012 IEM Nexrad'
                                    url="http://180.178.109.123:8080/geoserver/smartportal/wms"
                                    version='1.1.0'
                                    opacity={1}
                                    transparent
                                    layers="smartportal:portal"
                                    srs="EPSG:4326"
                                    format="image/png"
                                    bounds={bounds}

                                />
                            </LayersControl.Overlay> */}
                        {/* {lihat ? */}
                            <BoundWMS x1={x1} x2={x2} y1={y1} y2={y2} wms_id={wms_id} />
                            {/* : '' */}
                        {/* } */}

                        </LayersControl>
                        {/* <BoundWMS x1={x1} x2={x2} y1={y1} y2={y2} wms_id={wms_id} /> */}
                    </MapContainer>

                </Modal.Body>
            </Modal>
        </>
    )
}