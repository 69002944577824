import _Api from "../_Api";
import _serviceUpload from "./_serviceUpload";

class UploadFilesService {
  upload(fileName, file, onUploadProgress) {
    let formData = new FormData();

    formData.append("file", file);
    return _Api.post("/kantah/wms/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress
    });
  }

  downloadFile(filename) {
    // return http.get("/files");
    return _serviceUpload.get("service/transaksi/upload-file-pdf-tes/"+filename);
  }
}

export default new UploadFilesService();
