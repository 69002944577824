/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl, checkIsActive} from "../../../../_helpers";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
        ? " menu-item-active menu-item-open "
        : "";
  };

  return (
      <>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
          {/*begin::1 Level*/}

          {/*end::1 Level*/}
 
          <li className="menu-section ">
            <h4 className="menu-text">Main Menu</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li>
          {/*end::1 Level*/}
          <li
              className={`menu-item ${getMenuItemActive("/dashboard")}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}/>
            </span>
              <span className="menu-text">Dashboard</span>
            </NavLink>
          </li>
          {/* my page */}
          {/* <li
              className={`menu-item ${getMenuItemActive("/petugas-ukur")}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/petugas-ukur">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Petugas Ukur</span>
            </NavLink>
          </li> */}
          {/* end my pages */}

          <li
              className={`menu-item ${getMenuItemActive("/builder")}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manajemenuser">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Management User</span>
            </NavLink>
          </li>        
          <li
              className={`menu-item ${getMenuItemActive("/builder")}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/DataKantah">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}/>
            </span>
              <span className="menu-text">Data Kantah</span>
            </NavLink>
          </li>
          {/*end::1 Level*/}
        </ul>

        {/* end::Menu Nav */}
      </>
  );
}
