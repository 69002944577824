import React from 'react'
import Upload from '@material-ui/icons/Backup';
import Fullscreen from '@material-ui/icons/Fullscreen';
import AttachFile from '@material-ui/icons/AttachFile';
import Download from '@material-ui/icons/CloudDownload';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { Button, ThemeProvider } from '@material-ui/core';
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import SVG from "react-inlinesvg";
import { FullscreenExit } from '@material-ui/icons';



export function ButtonUplod(props) {
    return (
        <Button variant="contained" color="primary" onClick={props.onClick}
            type={props.type}
            variant="contained" color="primary"
            style={props.style}
            disabled={props.disabled}
            className={props.className}
        >
            {props.title} &nbsp;
            <Upload />

        </Button>
    )
}

 


export function ButtonAttachFile(props) {
    return (
        <Button variant="contained" color="primary" onClick={props.onClick}
            type={props.type}
            variant="contained" color="primary"
            style={props.style}
            disabled={props.disabled}
            className={props.className}
        >
            {props.title} &nbsp;
            <AttachFile />

        </Button>
    )
}

export function Prog_Progresbar(props) {
    return (
        <div
            className="progress-bar bg-success progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow={props.percentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: props.percentage + "%"}}
        >
            {props.children}
        </div>
    )
}

export function ButtonDownload(props) {
    return (
        <a onClick={props.onClick} style={props.style} className={props.className} className="btn btn-light btn-sm mx-1">
            <span className="svg-icon svg-icon-md svg-icon-primary">
            <Download /> {props.title} 
            </span>
        </a>
    )
}
export function ButtonFullScreen(props) {
    return (
        <a onClick={props.onClick} style={props.style} className={props.className} className="btn btn-light btn-sm mx-1">
            <span className="svg-icon svg-icon-md svg-icon-primary">
            <Fullscreen /> {props.title} 
            </span>
        </a>
    )
}
export function ButtonFullScreenExit(props) {
    return (
        <a onClick={props.onClick} style={props.style} className={props.className} className="btn btn-light btn-sm mx-1">
            <span className="svg-icon svg-icon-md svg-icon-primary">
            <FullscreenExit /> {props.title} 
            </span>
        </a>
    )
}

export function Btn_Delete(props) {
    return (
        <a onClick={props.onClick} style={props.style} className={props.className} className="btn btn-light btn-sm mx-1">
            <span className="svg-icon svg-icon-md svg-icon-primary">
            <DeleteForever /> {props.title} 
            </span>
        </a>
    )
}

 

