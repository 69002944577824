import { TableCell, TableRow, withStyles } from "@material-ui/core";

export const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: "#1e1e2d",
      color: theme.palette.common.white,
      fontSize:16
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  export const StyledTableRow = withStyles(theme => ({
    root: {
      '&:nth-off-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }))(TableRow);


 