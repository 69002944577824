import React, { useState, useEffect } from 'react';
import { useSubheader } from "../../../_metronic/layout";
import { connect, useSelector } from 'react-redux';
import _Api from "../../../services/_Api"
import { Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { StateToProps } from "../../../services/redux_/StateToProps";
import { injectIntl } from "react-intl";
import { Spinner, Form } from 'react-bootstrap'
import { MapContainer, TileLayer, Popup, Polygon, Marker, Polyline, LayersControl, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { config, db } from '../../service/firebase';
import firebase from 'firebase/app'
import { FirestoreCollection, FirestoreProvider } from "@react-firebase/firestore";
import L from 'leaflet';
import { iconMarker } from '../../modules/Peta/Icon/Marker'
import LocationMarker from './LocationMarker'
import MapLocation from './MapLocation'
import { FirebaseAppProvider } from 'reactfire'
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
});

const zoom = 13;

function HistoryLokasi() {

    const { user } = useSelector(state => state.auth);
    const [tanggal, setTanggal] = useState(null);
    const [email, setEmail] = useState(null);
    const [petugas, setPetugas] = useState([]);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [center, setCenter] = useState([-7.7823762951277455, 110.34292157739401]);

    useEffect(() => {
        loadPetugas();
        getTanggal();
    }, []);

    const getTanggal = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        let yyyy = today.getFullYear();

        let start = new Date();
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        let end = new Date();
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        // console.log("tanggal akhir :" + end);
        // console.log("tanggal start :" + start);

        today = yyyy + "-" + mm + "-" + dd;
        // let fire = new Date(today);
        // let fire = firebase.firestore.Timestamp.fromDate(new Date(today));

        setStart(start)
        setEnd(end)
        setTanggal(today)
    }

    const loadPetugas = () => {
        _Api.get(`kantah/${user.kantah.kode}/pu`)
            .then(res => {
                const persons = res.data;
                setPetugas(persons)
                // setLoading(false)
                // console.log(persons);
            })
            .catch(error => {
                // setLoading(false)
                console.log(error)
            })
    }

    const changePetugas = name => event => {
        setEmail(event.target.value);
        // loadHistory();
        // console.log(event.target.value)
        // console.log(firedate)
    }

    const changeTanggal = name => event => {
        setTanggal(event.target.value);
        // let fire = firebase.firestore.Timestamp.fromDate(new Date(event.target.value));
        // let tgl = new Date(event.target.value);

        let start = new Date(event.target.value);;
        start.setHours(0);
        start.setMinutes(0);
        start.setSeconds(0);

        let end = new Date(event.target.value);;
        end.setHours(23);
        end.setMinutes(59);
        end.setSeconds(59);

        // setStart(firebase.firestore.Timestamp.fromDate(start))
        // setEnd(firebase.firestore.Timestamp.fromDate(end))
        setStart(start)
        setEnd(end)

        // console.log("start : " + start);
        // console.log("End : " + end);
        // setFiredate(fire)
        // console.log(tanggal)
        // loadHistory();
    }


    return (

        <div className="row">
            <div className="col-md-12">
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-body">
                        <Form inline>
                            <Form.Label className="my-1 mr-2" htmlFor="inlineFormCustomSelectPref">Surveyor :</Form.Label>
                            <Form.Control as="select"
                                onChange={changePetugas("petugas")}
                                name="petugas"
                                className="my-1 mr-sm-6 col-md-4"
                                id="inlineFormCustomSelectPref"
                                defaultValue="Pilih" >
                                <option key={100} value="">Pilih</option>
                                {petugas.map((val, key) => (
                                    <option key={key} value={val.email}>{val.nama}</option>
                                ))}

                            </Form.Control>
                            <Form.Label className="my-1 mr-6" htmlFor="inlineFormCustomSelectPref">Tanggal History :</Form.Label>
                            <Form.Control
                                onChange={changeTanggal()}
                                type="date"
                                defaultValue={tanggal}
                            >
                            </Form.Control>
                        </Form>
                    </div>
                </div>
            </div>
            <div className="col-md-12">
                <div className="card card-custom gutter-b example example-compact">
                    {/* <div className="card-body"> */}

                    <MapContainer style={{ width: '100%', height: 500, zIndex: 3 }} center={center} zoom={18} scrollWheelZoom={true}>
                        <LayersControl position="topright">
                            <LayersControl.BaseLayer checked name="Roadmap">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'roadmap'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Satellite">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'satellite'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Terrain">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'terrain'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="Hybrid">
                                <ReactLeafletGoogleLayer apiKey='AIzaSyD2udNGXlLmABOIebDCUEOE1vFSTpZe0qg' type={'hybrid'} />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="OpenStreetMap.Mapnik">
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <LayersControl.BaseLayer name="OpenStreetMap.BlackAndWhite">
                                <TileLayer
                                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                    url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                />
                            </LayersControl.BaseLayer>
                            <FirebaseAppProvider firebaseConfig={config}>
                                <MapLocation email={email} start={start} end={end} />
                            </FirebaseAppProvider>

                            {/* <FirestoreProvider {...config} firebase={firebase}>
                                <FirestoreCollection key={1} path={"/smartgis/" + email + "/traking"} where={{ "field": "waktu", "operator": ">=", "value": start }} where={{ "field": "waktu", "operator": "<=", "value": end }} orderBy={[{ 'field': 'waktu', 'type': 'desc' }]} >
                                    {points => {

                                        return points.isLoading ? <Spinner animation="border" /> :
                                            <>
                                                <PoLine positions={points} />
                                                <LocationMarker positions={points} />
                                                
                                            </>

                                    }}
                                </FirestoreCollection>
                            </FirestoreProvider> */}
                        </LayersControl>
                    </MapContainer>

                    {/* </div> */}
                </div>
            </div>
        </div>
    )
}

export default injectIntl(connect(StateToProps, null)(HistoryLokasi));