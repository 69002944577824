import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Formik, Field } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Input } from "../../../../_metronic/_partials/controls";
import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { Button } from "bootstrap";
import _Api, { baseURL } from "../../../../services/_Api";
import { StateToProps } from "../../../../services/redux_/StateToProps";
import { DispatchToProps } from "../../../../services/redux_/DispatchToProps";
import axios from "axios";
import { auth as at, provider } from "../../../service/firebase";
import Swal from "sweetalert2";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const histori = useHistory();

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control form-control-solid h-auto py-5 px-6 ";
    if (meta.touched && meta.error) {
      result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
      result += " is-valid";
    }

    return result;
  };

  const sigInWithGoogle = async () => {
    await at
      .signInWithPopup(provider)
      .then((res) => {
        res.user.getIdToken().then(function(idToken) {
          // console.log(idToken)
          const objLogin = {
            token: idToken,
          };
          setLoading(true);
          axios
            .post(`${baseURL}login`, objLogin)
            .then((res) => {
              if (res.data) {
                var obj = {
                  authToken: '"' + res.data.access_token + '"',
                  user: JSON.stringify(res.data),
                  _persist: '{"version":-1,"rehydrated":true}',
                };
                // console.log(res.data[0])
                localStorage.setItem("persist:auth", JSON.stringify(obj));
                localStorage.setItem("Authorization", res.data.access_token);
                // console.log(props)
                window.location.href = "/dashboard";
              } else {
                setLoading(false);
                Swal.fire({
                  icon: "error",
                  title: "Oops...",
                  text: "Akun tidak ditemukan!",
                });

                // disableLoading();
                // setSubmitting(false);
                // setStatus(
                //   intl.formatMessage({
                //     id: "AUTH.VALIDATION.INVALID_LOGIN",
                //   })
                // );
              }
            })
            .catch((err) => {
              setLoading(false);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Akun tidak ditemukan!",
              });
              // disableLoading();
              // setSubmitting(false);
              // setStatus(
              //   intl.formatMessage({
              //     id: "AUTH.VALIDATION.INVALID_LOGIN",
              //   })
              // );
            });
        });
        // // console.log(res.user.getIdToken(true));
        // console.log(res.user.toJSON());
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="login-form login-signin">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <strong> LOGIN </strong>
        </h3>
        <p className="text-muted font-weight-bold">
          Silahkan pilih email anda !
        </p>
      </div>
      <button
        className={`btn btn-light font-weight-bold`}
        onClick={() => sigInWithGoogle()}
        type="button"
      >
        <div className="p-6">
          <img
            width={40}
            height={40}
            src="https://img.icons8.com/ios-filled/50/000000/google-logo.png"
            alt="google icon"
          />
        </div>
        <span className="m-6 p-6"> Continue with Google</span>
      </button>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(StateToProps, DispatchToProps)(Login));
