const ActionType ={
    TAMBAH_DATA :"TAMBAH_DATA",
    SET_JENIS :"SET_JENIS",
    MSG : {
        SUCCESS : "MSG-SUCCESS",
        ERROR : "MSG-ERROR",
    },
    DATA_KANTAH: "DATA_KANTAH",
    USER_LOGIN: "USER_LOGIN",
}
export default ActionType