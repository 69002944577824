import React from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_metronic/_helpers';

function ButtonDelete(props) {
    return (
        <a onClick={props.onClick} style={props.style} className={props.className} className="btn btn-light btn-sm mx-1">
            <span className="svg-icon svg-icon-md svg-icon-primary">
                <SVG
                    src={toAbsoluteUrl(
                        "/media/svg/icons/General/Trash.svg"
                    )}
                ></SVG> {props.title} 
            </span>
        </a>
    )
}

export default ButtonDelete
