import React, { useMemo } from 'react';
import { Rectangle, useMap } from 'react-leaflet'
import L from 'leaflet'
import 'leaflet.gridlayer.googlemutant'


export const BoundWMS = (props) => {
    const { x1, x2, y1, y2, wms_id } = props;
    const map = useMap();
    var point1 = L.latLng(x1, y1);
    var point2 = L.latLng(x2, y2);
    var bounds = L.latLngBounds(point1, point2);
    map.fitBounds(bounds)
    var myWMS = L.tileLayer.wms("https://portal.smartptsl.com/geoserver/portal/wms?", {
        layers: "portal:wms",
        format: 'image/png',
        transparent: true,
        version:"1.1.0",
        cql_filter: "wms_id=" + wms_id,
        zIndex: 600,
        opacity: 1,
        attribution: "WMS SmartPortal"
    });
    // var base = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    //     { id: 'MapID', tileSize: 512, zoomOffset: -1, attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' }
    // )
    // var allWMS = L.tileLayer.wms("http://180.178.109.123:8080/geoserver/smartportal/wms", {
    //     layers: "smartportal:portal",
    //     format: 'image/png',
    //     transparent: true,
    //     zIndex: 600,
    //     opacity: 1
    // });
    // var satellite = L.gridLayer.googleMutant({
    //     type : "satellite"
    // })

    // var roadmap = L.gridLayer.googleMutant({
    //     type : "roadmap"
    // })

    // var terrain = L.gridLayer.googleMutant({
    //     type : "terrain"
    // })

    // var hybrid = L.gridLayer.googleMutant({
    //     type : "hybrid"
    // })

    // var googleMap = {
    //     "Roadmap" : roadmap,
    //     "Satellite" : satellite,
    //     "Terrain" : terrain,
    //     "Hybrid" : hybrid,
    // }

    // var baseMaps = {
    //     // "Base Map": base,
    //     "WMS": myWMS,
    // };

    map.addLayer(myWMS);
    // map.addLayer(base);
    // map.addLayer(roadmap);
    // map.addLayer(satellite);
    // map.addLayer(terrain);
    // map.addLayer(hybrid);


    // L.control.layers(googleMap, baseMaps).addTo(map);



    // myWMS.setParams({ CQL_FILTER: "wms_id = 42" });

    const innerHandlers = useMemo(
        () => ({
            click() {
                map.fitBounds(bounds)
            },
        }),
        [map],
    )
    return (
        <>
            <Rectangle eventHandlers={innerHandlers} bounds={bounds} color={'red'} fillOpacity={0} />
        </>
    )
}
