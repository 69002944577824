import React, { useEffect, useState } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { ButtonGroup, Grid, IconButton, LinearProgress, Modal, TableFooter, TablePagination, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import _Api from '../../../../services/_Api';
import ButtonDelete from '../../../../services/_Button/Button-delete';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { StateToProps } from '../../../../services/redux_/StateToProps';
import { DispatchToProps } from '../../../../services/redux_/DispatchToProps';
import { StyledTableCell } from '../../../../services/Table/StyledTableCell';
import UploadWFS from './UploadWMS';
import ShowModal from '../../../../services/Modal/ShowModal';
import { Btn_Delete, ButtonDownload, ButtonUplod } from '../../../../services/_Button';
import uploadFilesService from '../../../../services/_Upload/upload-files.service';
import { ModalWms } from './ModalWms';
import { Button } from 'react-bootstrap'


const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
        overflowX: 'auto',
    },
    table: {
        minWidth: 700,
    },
}));

function ManajemenWMS(props) {
    const classes = useStyles();
    const [rows, setrows] = useState([])
    const [kantah, setKantah] = useState()
    const [show, setShow] = useState(false);
    const [x1, setX1] = useState(null);
    const [x2, setX2] = useState(null);
    const [y1, setY1] = useState(null);
    const [y2, setY2] = useState(null);
    const [wms_id, setWms] = useState(null);
    const histori = useHistory()


    const [kantahAktif, setKantahAktif] = useState("")
    const [loading, setloading] = useState(false)
    const [cek, setcek] = useState("")


    const [open, setOpen] = React.useState(false);

    // PAGINATE TABLE ===
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };




    // END PAGINATE ===

    const handleClose = () => {
        setOpen(false);
    };

    const setAll = (x1, x2, y1, y2, id) => {
        setX1(x1);
        setX2(x2);
        setY1(y1);
        setY2(y2);
        setWms(id);
        setShow(true);
    }


    const deleteKantah = (id) => {
        Swal.fire({
            title: 'Hapus file ?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.isConfirmed) {
                _Api.delete("/kantah/" + id).then(res => {
                    loadData()
                })

            }
        })
    }


    const handleClickOpen = (kth, id) => {
        setKantahAktif(kth)
        setcek(id)
        // alert(id)
        setOpen(true);
    };





    useEffect(() => {
        loadData()
    }, [])



    const loadData = () => {
        setloading(true)
        const id_kantah = props.userLogin.id_kantah
        _Api.get("/kantah/wms/list").then(res => {
            setrows(res.data)
            setloading(false)
        })
        setKantah(null)
    }



    const DownloadWMS = filename => {
        uploadFilesService.downloadFile(filename);
    }


    const deleteWMS = (id) => {
        Swal.fire({
            title: 'Hapus data ?',
            icon: 'warning',
            showCancelButton: true,
            cancelButtonText: 'Batal',
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya'
        }).then((result) => {
            if (result.isConfirmed) {
                _Api.delete("/kantah/wms/" + id + "/delete").then(res => {
                    loadData()
                })

            }
        })
    }


    const StyledTableRow = withStyles(theme => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme.palette.background.default,
            },
        },
    }))(TableRow);

    const renderData = (rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row, index) => (
        <StyledTableRow key={index}>
            <StyledTableCell>{index + 1}  </StyledTableCell>
            <StyledTableCell>{row.filename}</StyledTableCell>


            <StyledTableCell>
                <Button variant="light" onClick={() => setAll(row.x1, row.x2, row.y1, row.y2, row.id)} title="Show WMS"><i className="fa fa-map"></i></Button>
                {/* <ButtonDownload onClick={() => DownloadWMS(row.filename)} /> */}
                <Btn_Delete onClick={() => deleteWMS(row.id)} />
            </StyledTableCell>
        </StyledTableRow>
    ))

    return (
        <div className="row">
            <div className="col-md-12">
                <div className="card card-custom gutter-b example example-compact">
                    <div className="card-header">
                        <div className="card-title">
                            <h3 className="card-label"> MANAJEMEN WMS / WFS</h3>
                        </div>

                    </div>
                    {loading && <LinearProgress color="secondary" />}
                    <div className="card-body">
                        <Grid>
                            <Grid item xs style={{ margin: "10px 0 10px 0" }}>
                                {/* <ButtonNew onClick={() => setOpen(true)} /> */}
                                <ButtonUplod title="Upload File" onClick={() => setOpen(true)} />
                            </Grid>
                            <Grid item xs={1}> </Grid>

                        </Grid>
                        <Table>
                            <TableHead>
                                <TableRow hover>
                                    <StyledTableCell width="50">#</StyledTableCell>
                                    <StyledTableCell >Nama File</StyledTableCell>
                                    <StyledTableCell >Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderData}

                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        colSpan={3}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>

                        </Table>


                        <ShowModal
                            header="UPDLOAD FILE WMS / *.zip"
                            open={open}
                            onClose={handleClose}>
                            <UploadWFS />
                        </ShowModal>
                        <ModalWms x1={x1} x2={x2} y1={y1} y2={y2} wms_id={wms_id} show={show} setShow={setShow} />


                    </div>
                </div>
            </div>
        </div>

    );

}


export default injectIntl(connect(
    StateToProps,
    DispatchToProps
)(ManajemenWMS));

