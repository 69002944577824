import L from 'leaflet';

const iconMarker = new L.Icon({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const hijau = L.icon({
    iconUrl: require('../Img/hijau.svg'),
    // iconSize: [15,15],
    // iconAnchor: [15, 25],
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null

    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
  });

  const merah = L.icon({
    iconUrl: require('../Img/merah.svg'),
    // iconSize: [15,15],
    // iconAnchor: [15, 25],
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null

    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
  });

  const kuning = L.icon({
    iconUrl: require('../Img/kuning.svg'),
    // iconSize: [15,15],
    // iconAnchor: [15, 25],
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null

    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
  });

  const biru = L.icon({
    iconUrl: require('../Img/biru.svg'),
    // iconSize: [15,15],
    // iconAnchor: [15, 25],
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null

    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
  });

  const surveyor = L.icon({
    iconUrl: require('../Img/surveyor.svg'),
    iconSize: [40,40],
    // iconAnchor: [15, 25],
    // popupAnchor: null,
    // shadowUrl: null,
    // shadowSize: null,
    // shadowAnchor: null

    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    // iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    // iconSize: [10, 15],
    iconAnchor: [5, 15],
    // popupAnchor: [10,15 ],
    // className: 'redIcon', 
    shadowSize: [10, 15],
  });

export { iconMarker, surveyor, merah, hijau, biru, kuning };