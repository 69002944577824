import React, { useEffect, useState , useMemo} from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, ButtonGroup, Checkbox, FormControlLabel, Grid, IconButton, LinearProgress, TableFooter, TablePagination, TextField } from '@material-ui/core';
import _Api from '../../../services/_Api';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import ButtonDelete from '../../../services/_Button/Button-delete';
import ButtonUpdate from '../../../services/_Button/Button-update';
import ButtonNew from '../../../services/_Button/Button-New';
import { injectIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { StateToProps } from '../../../services/redux_/StateToProps';
import { DispatchToProps } from '../../../services/redux_/DispatchToProps';

import ButtonSave from '../../../services/_Button/Button-Save';
import ButtonDefault from '../../../services/_Button/Button-default';
import MappPetugasUkur from './MappPetugasUkur/MappPetugasUkur';
import { StyledTableCell, StyledTableRow } from '../../../services/Table/StyledTableCell';
import { ErrorMessage } from 'formik';
import { Form, ButtonGroup as Bg, Button as But } from 'react-bootstrap'
import { event } from 'jquery';
import { Memo } from 'react-is';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
  },
  table: {
    minWidth: 700,
  },
}));

function DataKantah(props) {
  const classes = useStyles();
  const [rows, setrows] = useState([])
  const [kantah, setKantah] = useState()
  const [showlabel, setshowlabel] = useState(false)
  const histori = useHistory()


  const [kantahAktif, setKantahAktif] = useState("")
  const [loading, setloading] = useState(false)
  const [cek, setcek] = useState("")


  const [open, setOpen] = React.useState(false);

  // PAGINATE TABLE ===
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const { user } = useSelector(state => state.auth);

  // END PAGINATE ===

  const handleClose = () => {
    setOpen(false);
  };


  const deleteKantah = (id) => {
    Swal.fire({
      title: 'Hapus data ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'Batal',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya'
    }).then((result) => {
      if (result.isConfirmed) {
        _Api.delete("/kantah/" + id).then(res => {
          loadData()
        })

      }
    })
  }


  const handleClickOpen = (kth, id) => {
    setKantahAktif(kth)
    setcek(id)
    // alert(id)
    setOpen(true);
  };

  const cekAuth = () => {
    _Api.get("users?id=" + user.id).then(res => {
      let url = res.data[0].role == "kantah"
        ? "/kantah?id=" + res.data[0].id_kantah
        : "/kantah";

      _Api.get(url).then(res => {
        setrows(res.data)
      })
    })
  }

  const loadData = () => {
    setloading(true)
    _Api.get("kantah").then(res => {
      setrows(res.data)
      setloading(false)
    })

    setKantah(null)
    // let user = props.userLogin;
    // let url = user.role == "kantah"
    //   ? "/kantah?id="+user.id_kantah
    //   : "/kantah";


    // _Api.get(url).then(res => {
    //   setrows(res.data)
    //   // setloading(false)
    // })
    // setKantah(null)
  }


  useEffect(() => {
    loadData()
  }, [])



  const handleChange = name => event => {
    setKantah({ ...kantah, [name]: event.target.value });
  };

  const handleSave = () => {
    try {
      setloading(true)
      // let doSave = kantah.kode ?
      //   _Api.put("/user/kantah/" + kantah.kode, kantah) :
      let doSave = _Api.post("kantah ", kantah)
      doSave.then(res => {
        loadData()
        setKantah(null)
      }).catch(err => {
        setloading(false)
      })
    } catch (error) {
      setloading(false)
      // ErrorMessage('Gagal simpan kantah ...')
    }

  }

  const setAksesPengtan = (e) => {
    if (e.target.checked) {
      _Api.get(`login-as/${user.id}`).then(res => {
        setshowlabel(true)
      })
    }
  }

  // const loginAs = ()=>{
  //   _Api.get(`login-as/${user.id}`).then(res => {
  //     console.log(res)
  //     setshowlabel(true)
  //   })
  // }

  const setAccessPengtan = (kantah) => {
    setloading(true)
    kantah.form = !kantah.form
    _Api.put(`kantah/${kantah.kode}`, kantah).then(res => {
      console.log(res)
      loadData()
    }).catch(err => {
      setloading(false)
      console.log(err)
    })
  }

  // const Cek = useMemo((props)=>{
  //   const {data, index} = props;
  //   return(
  //     <Form.Check
  //         checked={data.form}
  //         type="switch"
  //         id={"custom-switch-" + index}
  //         label="Pengtan"
  //         onChange={() => setAccessPengtan(data)}
  //         defaultChecked={data.form}
        
  //       />
  //   )
  // })

  const renderData = (rowsPerPage > 0
    ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    : rows
  ).map((row, index) => (
    <StyledTableRow key={index}>
      <StyledTableCell>{index + 1}  </StyledTableCell>
      <StyledTableCell>{row.nama}</StyledTableCell>

      <StyledTableCell>
        <ButtonDefault onClick={() => handleClickOpen(row, row.kode)} title="Mapping Petugas Ukur" />
      </StyledTableCell>
      <StyledTableCell>
        <Bg size="sm" aria-label="Basic example">
          <But size="sm" variant="danger" onClick={() => deleteKantah(row.kode)} title="Delete"><i className="fa fa-trash"></i></But>
          <But size="sm" variant="secondary" onClick={() => setKantah(row)} title="Update"><i className="fa fa-edit"></i></But>
          {/* <But size="sm" variant="primary" onClick={() => loginAs(row.kode)} title="Login As"><i className="fa fa-key"></i></But> */}
        </Bg>
        {/* <ButtonDelete onClick={() => deleteKantah(row.kode)} title="Delete" />
        <ButtonUpdate onClick={() => setKantah(row)} title="Update" />
        <FormControlLabel style={{ marginTop: "15px" }}
          control={<Checkbox checked={showlabel} onChange={setAksesPengtan} name="pengtan" />}
          label="Akses Pengtan"
        /> */}

      </StyledTableCell>
      <StyledTableCell>
        {/* <Cek data={row} index={index}/> */}
        <Form.Check
          checked={row.form}
          type="switch"
          id={"custom-switch-" + index}
          label="Pengtan"
          onClick={() => setAccessPengtan(row)}
          defaultChecked={row.form}
        
        />
      </StyledTableCell>
    </StyledTableRow>
  ))


  return (
    <div>
      <Paper className={classes.root}>
        <Grid container>
          <Grid item xs={3} style={{ marginLeft: "10px" }}>
            <TextField
              value={kantah ? kantah.kode : ""}
              onChange={handleChange("kode")}
              label="Kode"
              margin="normal"
              variant="filled"
            />
          </Grid>

          <Grid item xs style={{ marginLeft: "10px" }}>
            <TextField
              value={kantah ? kantah.nama : ""}
              onChange={handleChange("nama")}
              label="Nama Kantor Pertanahan (Kantah)"
              margin="normal"
              variant="filled"
            />
          </Grid>

          {/* <Grid item xs={1} style={{ margin: "25px 5px 0 10px" }}>
            <ButtonNew onClick={() => setloading(true)} />
          </Grid> &nbsp; */}
          <Grid item xs={1} style={{ margin: "25px 5px 0 10px" }}>
            <ButtonSave loading={loading}
              onClick={() => handleSave()} />
          </Grid>


        </Grid>
        {loading && <LinearProgress color="secondary" />}
        <Table>
          <TableHead>
            <TableRow hover>
              <StyledTableCell width="50">#</StyledTableCell>
              <StyledTableCell >Kantah</StyledTableCell>
              <StyledTableCell >PU</StyledTableCell>
              <StyledTableCell >Action</StyledTableCell>
              <StyledTableCell >Form</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData}

          </TableBody>

          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>

        </Table>


        <div>

          <MappPetugasUkur onClick={handleClose} open={open} kantahAktif={kantahAktif}> </MappPetugasUkur>
        </div>

      </Paper>


    </div>
  );

}


export default injectIntl(connect(
  StateToProps,
  DispatchToProps
)(DataKantah));

