import React, { useEffect, useState } from 'react'
import { Button, ButtonGroup, Grid, IconButton, LinearProgress, Modal, Switch, TableContainer, TableFooter, TablePagination, TextField, ThemeProvider, Typography, useTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import ButtonSave from '../../../../services/_Button/Button-Save';
import _Api from '../../../../services/_Api';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { StyledTableCell, StyledTableRow } from '../../../../services/Table/StyledTableCell';
import ButtonDelete from '../../../../services/_Button/Button-delete';
import ButtonUpdate from '../../../../services/_Button/Button-update';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Swal from 'sweetalert2';


function MappPetugasUkur(props) {

    // alert(props.kantahAktif.id)
    const [rows, setrows] = useState([])
    const [dataPu, setdataPu] = useState()

    // PAGINATE TABLE ===
    const [page, setPage] = React.useState(0);
    const [loading, setloading] = useState(true)
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    // END PAGINATE ===


    const loadData = () => {
        _Api.get(`kantah/${props.kantahAktif.kode}/pu`).then(res => {
            setrows(res.data)
            setdataPu(null)
            setloading(false)
        })
    }



    const deletePetugasUkur = (id) => {
        setloading(true)
          var answer = window.confirm("Hapus Surveyor?");
          if (answer) {
            _Api.delete("pu/" + id).then(err =>
                loadData()
            )
          }
          



        // Swal.fire({
        //   title: 'Hapus data ?',
        //   icon: 'warning',
        //   showCancelButton: true,
        //   cancelButtonText: 'Batal',
        //   confirmButtonColor: '#3085d6',
        //   cancelButtonColor: '#d33',
        //   confirmButtonText: 'Ya'
        // }).then((result) => {
        //   if (result.isConfirmed) {
        //     _Api.delete("petugaspu/" + id).then(err =>
        //         loadData()    
        //     )


        //   }
        // })
    }

    const handleChange = name => event => {
        setdataPu({
            ...dataPu,
            kantah: props.kantahAktif.kode,
            [name]: event.target.value
        });
    };

    useEffect(() => {


    }, [])

    const renderData = (rowsPerPage > 0
        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : rows
    ).map((row, index) => (
        <StyledTableRow key={index}>
            <StyledTableCell>{index + 1}  </StyledTableCell>
            <StyledTableCell>{row.nama}</StyledTableCell>
            <StyledTableCell>{row.email}</StyledTableCell>

            <StyledTableCell style={{ display: "flex" }}>
                <ButtonDelete onClick={() => deletePetugasUkur(row.id)} />
                <ButtonUpdate onClick={() => setdataPu(row)} />
                {/* <Switch color="Secondary" /> */}

            </StyledTableCell>
        </StyledTableRow>
    ))

    const handleSave = () => {
        setloading(true)
        let doSave = dataPu.id ? 
            _Api.put("/pu/" + dataPu.id, dataPu) :
            _Api.post("pu/", dataPu)

        doSave.then(res => {
            loadData()
        }).catch(err=>{
            setloading(false)
        })
    }


    return (
        <div>
            <Dialog
                open={props.open}
                onEntering={loadData}
                // style={{width:"400px"}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                {/* <DialogTitle></DialogTitle> */}
                <DialogContent>
                    <h3 style={{ textAlign: "center" }}> Surveyor Kantah : [{props.kantahAktif.nama}] </h3>
                    <DialogContentText>
                        <Grid container style={{ display: "flex" }}>
                            <ValidatorForm onSubmit={handleSave} className="flex">

                                    <TextValidator
                                        label="Nama Surveyor"
                                        value={dataPu ? dataPu.nama : ""}
                                        onChange={handleChange('nama')}
                                        style={{ width:"450px" }}
                                        margin="normal"
                                        variant="filled"
                                        validators={['required']}
                                        errorMessages={['Silahkan dilengkapi ']}
                                    />
                                    &nbsp;
                                    <TextValidator
                                        label="Email"
                                        value={dataPu ? dataPu.email : ""}
                                        onChange={handleChange('email')}
                                        margin="normal"
                                        variant="filled"
                                        validators={['required', 'isEmail']}
                                        errorMessages={['this field is required', 'email is not valid']}
                                    />
                                    &nbsp;
                                   
                                <Grid item style={{ marginTop: "25px", marginLeft:"20px" }}>
                                    <ButtonSave loading={loading} type="submit" />
                                </Grid>  

                            </ValidatorForm>

                        </Grid>
                        {loading && <LinearProgress color="secondary" />}
                        <Table>
                            <TableHead>
                                <TableRow hover>
                                    <StyledTableCell width="50">No</StyledTableCell>
                                    <StyledTableCell >Nama Surveyor</StyledTableCell>
                                    <StyledTableCell  width="200">Email</StyledTableCell>
                                    <StyledTableCell width="50" >Action</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {renderData}

                            </TableBody>

                            <TableFooter>
                                <TableRow>
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                                        colSpan={3}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </TableRow>
                            </TableFooter>

                        </Table>

                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={props.onClick} color="primary">
                        Close</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default MappPetugasUkur

// export default injectIntl(connect(
//     StateToProps,
//     DispatchToProps
//   ) (MappPetugasUkur));

