
import React, { useEffect, useState } from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import _Api from '../../../services/_Api';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { connect } from 'react-redux'
import ButtonSave from '../../../services/_Button/Button-Save';
import AlertSweet, { alert_success } from '../../../services/Alert/AlertSweet';
import { injectIntl } from 'react-intl';
import { StateToProps } from '../../../services/redux_/StateToProps';
import { DispatchToProps } from '../../../services/redux_/DispatchToProps';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import { Container, Grid } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';


const role = [
    {
        value: 'admin',
        label: 'Admin',
    },
    {
        value: 'kantah',
        label: 'Kantah',
    },

];


const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: "100%"
    },
    w100: {
        width: "100%"
    },
    dense: {
        marginTop: 16,
    },
    menu: {
        width: 200,
    },
    saveButton: {
        position: "absolute",
        right: "150px",
        width: "200px"
    },
    button: {
        margin: theme.spacing(1),
    },
    leftIcon: {
        marginRight: theme.spacing(1),
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    iconSmall: {
        fontSize: 20,
    },

}));

function InputUserBaru(props) {

    const [loading, setloading] = useState(false)

    const classes = useStyles();
    const [message, setMessage] = useState(false);


    // const [value, setValue] = useState(options[0]);

    const [bedaPass, setbedaPass] = useState(false);
    const [listkantah, setlistkantah] = useState([]);





    const [id] = props.match.params.id
    const isAdd = props.match.params.id == "0" ? true : false
    const histori = useHistory();


    const [kantah, setkantah] = useState(listkantah[0]);

    const [datauser, setDatauser] = useState({});

    const handleSave = (e) => {
        e.preventDefault()

        setloading(true)

        if (datauser.password != datauser.passwordlagi) {
            setbedaPass(true)
            return
        }

        let action = id == '0'
            ? _Api.post("create", datauser)
            : _Api.put("create/" + id, datauser);
        action.then(res => {
            console.log(res)
            let go = id != "0" ? histori.goBack() : ""
            alert_success("Berhasil simpan data ...")

            setloading(true)
            setbedaPass(false)
            histori.push("/manajemenuser")

        }).catch(err => {
            setloading(false)
            Swal.fire({
                title: "Gagal simpan data !", icon: "error"
            })
        })
    }

    const loadData = () => {
        _Api.get(id + "/get").then(res => {
            setDatauser(res.data)
            setloading(false)
        })
    }

    const getKantah = () => {
        _Api.get("kantah").then(res => {
            setlistkantah(res.data)
        })
    }

    useEffect(() => {
        getKantah()

        if (id == "0")
            console.log(id)
        else
            loadData();
    }, [])

    const handleChange = name => event => {
        setDatauser({
            ...datauser,
            "kantah": kantah ? kantah.kode : "",
            "expire_at": null,
            [name]: event.target.value
        });

    };

    return (
        <Container>
            <Grid item xs> <h2> MANAJEMEN USER </h2> </Grid>
            <Grid item xs={11}>
                <ValidatorForm onSubmit={handleSave}>

                    <hr />
                    <Autocomplete
                        required
                        label="Kantah"
                        margin="normal"
                        variant="filled"
                        className={classes.textField}
                        onChange={(event, newValue) => {
                            setkantah(newValue);
                            console.log(newValue)
                        }}
                        options={listkantah}
                        getOptionSelected={(option, value) => option.kode === value.kode}
                        getOptionLabel={(option) => option.nama}
                        renderInput={(params) =>
                            <TextField {...params}
                                label="Kantah"
                                onChange={handleChange('cek')}
                                style={{ marginLeft: "0px" }}
                                className={classes.textField}
                                margin="normal"
                                variant="filled" />}
                    />

                    <TextValidator
                        label="Username"
                        value={datauser.username}
                        onChange={handleChange('username')}
                        margin="normal"
                        className={classes.textField}
                        variant="filled"
                        validators={['required']}
                        errorMessages={['Silahkan dilengkapi ']}
                    />
                    <TextValidator
                        label="Email"
                        className={classes.textField}
                        value={datauser.email}
                        onChange={handleChange('email')}
                        margin="normal"
                        variant="filled"
                        validators={['required', 'isEmail']}
                        errorMessages={['this field is required', 'email is not valid']}
                    />
                    <TextValidator
                        className={classes.textField}
                        label="Nama Lengkap"
                        value={datauser.nama}
                        onChange={handleChange('nama')}
                        margin="normal"
                        variant="filled"
                        validators={['required']}
                        errorMessages={['Silahkan dilengkapi ']}

                    />


                    <TextValidator
                        className={classes.textField}
                        validators={['required']}
                        errorMessages={['Silahkan dilengkapi ']}
                        select
                        label="Role"
                        value={datauser.role}
                        onChange={handleChange('role')}
                        SelectProps={{
                            MenuProps: {
                                className: classes.menu,
                            },
                        }}
                        margin="normal"
                        variant="filled"
                    >
                        {role.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextValidator>

                    <TextValidator
                        label="Password"
                        // validators={isAdd ? ['required'] : ""}
                        errorMessages={['Silahkan dilengkapi ']}
                        type="password"
                        style={{ margin: 8, backgroundColor: bedaPass ? "#ff000054" : "" }}
                        placeholder="Password"
                        fullWidth
                        onChange={handleChange('password')}
                        margin="normal"
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextValidator
                        className={classes.textField}
                        // validators={isAdd ? ['required'] : ""}
                        errorMessages={['Silahkan dilengkapi ']}
                        label="Password Lagi"
                        type="password"
                        style={{ margin: 8, backgroundColor: bedaPass ? "#ff000054" : "" }}
                        onChange={handleChange('passwordlagi')}
                        placeholder="Password Lagi"
                        fullWidth
                        margin="normal"
                        variant="filled"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <TextField
                        id="date"
                        label="Login Expire"
                        type="date"
                        margin="normal"
                        onChange={handleChange('expire_at')}
                        variant="filled"
                        // defaultValue="2017-05-24"
                        className={classes.textField}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                   



                    {/* <input type="submit"> Simpan </input> */}


                    <ButtonSave loading={loading} type="submit" className={classes.button} />

                    {/* <button type="button" onClick={()=>props.TambahData()}> Tes Redux </button> */}
                    {/* <button type="button" onClick={()=>props.setKantahAktif({kantah:"Praya barat", id:23})}> Cek Kantah </button>
                    total : {props.total}
                    kantahAktif : {props.kantahAktif} */}

                </ValidatorForm>
            </Grid>
            <Grid item xs> </Grid>

        </Container>
    );
}


export default injectIntl(connect(
    StateToProps,
    DispatchToProps
)(InputUserBaru))



