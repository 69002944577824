import { Button } from '@material-ui/core';
import React, { useState, useStyles } from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import SaveIcon from '@material-ui/icons/SaveAlt';
import { FormatColorReset } from '@material-ui/icons';

function ButtonSave(props) {
    const {loading} = props 
    return (
        <Button variant="contained" color="primary" 
            onClick={  props.onClick}
            type={props.type}
            variant="contained" color="primary"
            style={props.style}
            disabled = {props.disabled}
            className={props.className}
            disabled={loading}
        >
            {loading ?
                <span className="ml-3 spinner spinner-white"></span> :""
            }
            Save &nbsp;
            <SaveIcon />

        </Button>
    )
}

export default ButtonSave
