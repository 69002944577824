import { Button } from '@material-ui/core';
import React, { useStyles } from 'react'
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from '../../_metronic/_helpers';
import SaveIcon from '@material-ui/icons/PostAdd';

function ButtonNew(props) {

    return (
        <Button variant="contained" color="primary" onClick={props.onClick}
            variant="contained" color="primary"
            className = {props.className}
            style = {props.style}
        >
            New &nbsp;
            <SaveIcon />
        </Button>
    )
}

export default ButtonNew
