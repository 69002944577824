import React from 'react';
import _Api from "../../../services/_Api"
import { Spinner} from 'react-bootstrap'
import { Polyline} from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import LocationMarker from './LocationMarker'
import { useFirestore, useFirestoreCollectionData } from 'reactfire'

function MapLocation(props) {

    const { start, end, email } = props;
    const burritoRef = useFirestore()
        .collection("/smartgis/" + email + "/traking")
        .where('waktu', '>=', start)
        .where('waktu', '<=', end)
        .orderBy('waktu','desc')
        // .limit(10);

    // subscribe to a document for realtime updates. just one line!
    const { status, data } = useFirestoreCollectionData(burritoRef);
    const PoLine = React.memo((props) => {
        let poli = [];

        props.positions.map(cek => {
            poli.push([cek.lokasi.latitude, cek.lokasi.longitude])
        })
        return (
            <Polyline positions={poli}></Polyline>
        )
    })


    if (status === 'loading') {
        return <Spinner animation="border" />
    }
    return (
        <>
            <LocationMarker positions={data} />
            <PoLine positions={data} />
        </>
    )

}

export default MapLocation;