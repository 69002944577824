import _Api from "../../../../services/_Api";

import {
  LOGIN_URL,
  // ME_URL,
  // REGISTER_URL,
  // REQUEST_PASSWORD_URL
} from "../_redux/authCrud";

export default function mockAuth(props) {

  // const histori = React.useHistory();

  props.onPost(LOGIN_URL).reply(({ data }) => {
    const { email, password } = JSON.parse(data);

    // if (email && password) {
    //   const user = userTableMock.find(
    //     x =>
    //       x.email.toLowerCase() === email.toLowerCase() &&
    //       x.password === password
    //   );

    //   if (user) {
    //     // localStorage.setItem('persist:demo1-auth','{"_persist":"{\"version\":-1,\"rehydrated\":true}","authToken":"\"access-token-8f3ae836da744329a6f93bf20594b5cc\"","user":"{\"id\":1,\"username\":\"admin\",\"email\":\"admin@demo.com\",\"accessToken\":\"access-token-8f3ae836da744329a6f93bf20594b5cc\",\"refreshToken\":\"access-token-f8c137a2c98743f48b643e71161d90aa\",\"roles\":[1],\"pic\":\"/media/users/300_25.jpg\",\"fullname\":\"Sean\",\"occupation\":\"CEO\",\"companyName\":\"Keenthemes\",\"phone\":\"456669067890\",\"address\":{\"addressLine\":\"L-12-20 Vertex, Cybersquare\",\"city\":\"San Francisco\",\"state\":\"California\",\"postCode\":\"45000\"},\"socialNetworks\":{\"linkedIn\":\"https://linkedin.com/admin\",\"facebook\":\"https://facebook.com/admin\",\"twitter\":\"https://twitter.com/admin\",\"instagram\":\"https://instagram.com/admin\"}}"}')
    //     // window.location.href="/dashboard"
    //     return [200, { ...user, password: undefined }];
    //   }
    // }

    // return [400];


    if (email && password) {
      _Api.get("/users?email=" + email + "&password=" + password).then(res => {

        if (res.data[0] ) {
          var obj =
          {
            'authToken': '"' + res.data[0].accessToken + '"',
            'user': JSON.stringify(res.data[0]),
            '_persist': '{"version":-1,"rehydrated":true}',
          };
          localStorage.setItem('persist:auth', JSON.stringify(obj));
          window.location.href = "/dashboard"
          return [200];
        }

      })

    }
    return [400];
  });

  // mock.onPost(REGISTER_URL).reply(({ data }) => {
  //   const { email, fullname, username, password } = JSON.parse(data);

  //   if (email && fullname && username && password) {
  //     const user = {
  //       id: generateUserId(),
  //       email,
  //       fullname,
  //       username,
  //       password,
  //       roles: [2], // Manager
  //       accessToken: "access-token-" + Math.random(),
  //       refreshToken: "access-token-" + Math.random(),
  //       pic: process.env.PUBLIC_URL + "/media/users/default.jpg"
  //     };

  //     userTableMock.push(user);

  //     return [200, { ...user, password: undefined }];
  //   }

  //   return [400];
  // });

  // mock.onPost(REQUEST_PASSWORD_URL).reply(({ data }) => {
  //   const { email } = JSON.parse(data);

  //   if (email) {
  //     const user = userTableMock.find(
  //       x => x.email.toLowerCase() === email.toLowerCase()
  //     );

  //     if (user) {
  //       user.password = undefined;

  //       return [200, { ...user, password: undefined }];
  //     }
  //   }

  //   return [400];
  // });

  // mock.onGet(ME_URL).reply(({ headers: { Authorization } }) => {
  //   const accessToken =
  //     Authorization &&
  //     Authorization.startsWith("Bearer ") &&
  //     Authorization.slice("Bearer ".length);

  //   if (accessToken) {
  //     const user = userTableMock.find(x => x.accessToken === accessToken);

  //     if (user) {
  //       return [200, { ...user, password: undefined }];
  //     }
  //   }

  //   return [401];
  // });

  // function generateUserId() {
  //   const ids = userTableMock.map(el => el.id);
  //   const maxId = Math.max(...ids);
  //   return maxId + 1;
  // }

}
